<!--<div class="printarea">-->
<!--    <router-outlet></router-outlet>-->
<!--</div>-->

<div style="width: 100%">
    <div class="printviewTitle"><h2>{{ 'Report.DailyChart.printView' | instantTranslate:printService.language | async }}</h2></div>
    <div *ngIf="printService.progressBarNeeded" style="height: 3px; width: 100%">
        <mat-progress-bar class="_mat-animation-noopable" mode="determinate" [value]="printService.progressBarValue"></mat-progress-bar>
    </div>
    <div class="printviewTitle" style="padding-top: 8rem">
        <h4 style="font-size: 1rem">{{ 'Report.DailyChart.PreparingPrintView' | instantTranslate:printService.language | async }}</h4>
        <p [ngStyle]="{'visibility': dailyChartService.getReportContext(printService.tab)?.reportContext?.indexOfLastChartToLoad !== -1 ? 'visible' : 'hidden'}">
            {{ 'Report.DailyChart.Status' | instantTranslate:printService.language | async }}:&nbsp;&nbsp;
            {{ dailyChartService.getReportContext(printService.tab)?.reportContext?.indexOfLastChartToLoad }}/{{ dailyChartService.getReportContext(printService.tab)?.reportContext?.dailyChartInputs?.length }}
        </p>
        <button mat-stroked-button color="primary" (click)="navigateBack()">
            {{ 'Report.General.Abort' | instantTranslate:printService.language | async }}
        </button>
    </div>
</div>

<div class="printarea">
    <table xmlns="http://www.w3.org/1999/html">
        <thead>
        <tr>
            <td>
                <div class="header-space">
                    <div class="headerLeft">
                        {{ printService.companyName }}
                    </div>
                    <div class="headerCenter">
                        <span style="padding-right: 10px">
                            {{ printService.reportName | instantTranslate:printService.language | async }}
                        </span>
                    </div>
                    <div class="headerRight">
                        {{ 'Report.DailyChart.reportGenerated' | instantTranslate:printService.language | async }}<br/>
                        {{ printService.reportGenerated.format('Report.General.TimeFormat' | instantTranslate:printService.language | async) }}
                    </div>
                </div>
            </td>
        </tr>
        </thead>
        <tbody>
            <tr>
                <td>
                    <div style="width: 99%">
                        <router-outlet></router-outlet>
                    </div>
                </td>
            </tr>
        </tbody>
        <tfoot>
            <tr>
                <td>
                    <div class="footer-space">
                        <div class="footer">
                            <div class="footerLeft">
                                Designed & Developed by R-Szoft
                            </div>
                            <div class="footerCenter"></div>
                            <div class="footerRight">
                                &nbsp;TachoAnalytics
                            </div>
<!--                            <div class="footerRight">-->
    <!--                            <div class="page-number"></div>-->
    <!--                            {{ 'Report.DailyChart.reportGenerated' | instantTranslate: dailyChartService.chartLanguage | async }}<br/>-->
    <!--                            {{ dailyChartResponse.reportGenerated.format('Report.General.TimeFormat' | instantTranslate: dailyChartService.chartLanguage | async) }}-->
<!--                            </div>-->
                        </div>
                    </div>
                </td>
            </tr>
        </tfoot>
    </table>
</div>





<!--<div class="header">-->
<!--    <div class="headerLeft">-->
<!--        &nbsp;-->
<!--    </div>-->
<!--    <div class="headerCenter">-->
<!--        <mat-icon svgIcon="driver_icon" class="dailyChartIcons margin-right_10"></mat-icon>-->
<!--        {{ '' | nameChart:dailyChartResponse.driverInfo.cardHolderFirstNames:dailyChartResponse.driverInfo.cardHolderSurname:('Report.DailyChart.nameFormat' | instantTranslate: dailyChartService.chartLanguage | async) }}-->
<!--        {{dailyChartResponse.driverInfo.cardNumber}}-->
<!--        {{dailyChartResponse.driverInfo.cardIssuingMemberState}}-->
<!--    </div>-->
<!--</div>-->
<!--<div class="footer">Designed @ Developed by R-Szoft</div>-->
