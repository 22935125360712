import { Component } from '@angular/core';
import { PROFILE } from '../../../helpers/globals';

@Component({
    selector: 'app-branding',
    templateUrl: './branding.component.html',
    styleUrls: ['./branding.component.css']
})
export class BrandingComponent {
    profile = PROFILE;

    getStyle(){
        const hostname = window.location.hostname;
        if (this.profile === 3){
            return `#0F1A36 !important`;
        }
        if(hostname.includes('localhost')){
            return `#15803d !important`;
        }
        if(hostname.includes('staging')){
            return '#fbbf24 !important';
        }
        if(hostname.includes('sandbox')){
            return '#f43f5e !important';
        }
        return '';
    }
}
