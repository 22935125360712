export function downloadContent(response) {
    const headers = response.headers;
    const contentDisposition = headers?.get('content-disposition');
    const filename = contentDisposition?.split('=')[1] || 'TachoAnalytics.csv';

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(response.body);
    link.download = filename;
    link.click();
}
