import {CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';

import {RouterModule} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {AppCommonModule} from './components/common/app-common.module';
import {AppComponent} from './app.component';

import {RoutePartsService} from './services/route-parts/route-parts.service';
import {CookieModule, CookieService} from 'ngx-cookie';
import {AuthErrorHandler} from './services/auth/auth-error-handler';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AuthGuard} from './services/auth/auth-guard.service';
import {AuthService} from './services/auth/auth.service';

import {MyXsrfInterceptor} from './interceptors/my-xsrf.interceptor';
import {CommonModule, DecimalPipe} from '@angular/common';
import {DisclaimerComponent} from './views/disclaimer/disclaimer.component';
import {CookiesComponent} from './views/cookies/cookies.component';
import {GdprComponent} from './views/gdpr/gdpr.component';
import {PatchnotesComponent} from './views/patchnotes/patchnotes.component';
import {UserActivationComponent} from './views/sessions/user-activation/user-activation.component';
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MaintenanceComponent} from './views/maintenance/maintenance.component';
import {BrowserModule} from '@angular/platform-browser';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {AppSettings} from './app.settings';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {TestcomponentComponent} from './views/testcomponent/testcomponent.component';
import {AlertBar} from './helpers/alert-bar';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatNativeDateModule} from '@angular/material/core';
import {MatBadgeModule} from '@angular/material/badge';
import { FeedbackPopupComponent } from './views/popups/feedback-popup/feedback-popup.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import {MatInputModule} from '@angular/material/input';
import {FeedbackService} from './services/feedback/feedback.service';
import {ConfirmationDialogComponent} from './views/settings/connected-services/confirmation-dialog/confirmation-dialog.component';
import {rootRouterConfig} from './app.routes';
import {NamePipe} from './pipes/common/name-pipe';
import { MatGridListModule } from '@angular/material/grid-list';
import {UpdateReleaseModalComponent} from './views/release/update-release-modal/update-release-modal.component';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import { DriverChartComponent } from './views/charts/driver-chart/driver-chart.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {PaginationComponent} from './components/common/pagination/pagination.component';
import { IconSnackBarComponent } from './services/backend-availability/backend-availability.service';
import {MatListModule} from '@angular/material/list';
import {TableModule} from 'primeng/table';
import { UpdaterCheckerComponent } from './views/updater-checker/updater-checker.component';
import { BillableTerminalsComponent } from './views/billable-terminals/billable-terminals.component';
import {MatSelectModule} from '@angular/material/select';
import {TreeTableModule} from 'primeng/treetable';
import {InputTextModule} from 'primeng/inputtext';
import { NotificationManagementComponent } from './views/notification-management/notification-management.component';
import {TimelineModule} from 'primeng/timeline';
import {TagModule} from 'primeng/tag';
import { ChartModule } from 'primeng/chart';
import {PaginatorIntService} from './services/rszoft/paginator-int.service';
import {CharttranslationService} from './services/rszoft/charttranslation.service';
import {QRCodeModule} from 'angularx-qrcode';
import {MobileAppsComponent} from './views/mobile-apps/mobile-apps.component';
import { CustomTranslateLoader } from './services/custom-translate-loader.service';
import { T2UpdateBillingComponent } from './views/t2-update-billing/t2-update-billing.component';
import { T2ProUpdateBillingComponent } from './views/t2-pro-update-billing/t2-pro-update-billing.component';
import { SubscriptionComponent } from './views/subscription/subscription.component';
import { IsProUserComponent } from './views/is-pro-user/is-pro-user.component';
import {ToastModule} from 'primeng/toast';
import { ManageSubCompanyComponent } from './views/manage-sub-company/manage-sub-company.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SkeletonModule } from 'primeng/skeleton';
import { ReportSubscriptionBannerComponent } from './components/report-subscription-banner/report-subscription-banner.component';
import { ManageSubCompanyBillingComponent } from './views/manageSubCompanyBilling/manage-sub-company-billing/manage-sub-company-billing.component';
import { CalendarModule } from 'primeng/calendar';
import {InputSwitchModule} from 'primeng/inputswitch';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {DialogModule} from 'primeng/dialog';
import {RadioButtonModule} from 'primeng/radiobutton';
import {InputTextareaModule} from 'primeng/inputtextarea';
import { DropdownModule } from 'primeng/dropdown';
import {SplitButtonModule} from 'primeng/splitbutton';
import { ReplaceValuesPipe } from './utils/replace-values.pipe';
import {OverlayPanelModule} from 'primeng/overlaypanel';

import './moment.override';
import { ReplaceAllValuesPipe } from './utils/replace-all-values-pipe';






export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        HttpClientModule,
        AppCommonModule,
        MatBadgeModule,
        PerfectScrollbarModule,
        FlexLayoutModule,
        MatNativeDateModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                // useFactory: createTranslateLoader,
                useClass: CustomTranslateLoader,
                deps: [HttpClient]
            }
        }),
        RouterModule.forRoot(rootRouterConfig, {useHash: true, relativeLinkResolution: 'legacy'}),
        CookieModule.forRoot(),
        MatProgressSpinnerModule,
        MatTooltipModule,
        MatIconModule,
        MatButtonModule,
        MatCardModule,
        MatSnackBarModule,
        MatGridListModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatCheckboxModule,
        MatRadioModule,
        MatInputModule,
        FormsModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatProgressSpinnerModule,
        MatDatepickerModule,
        TableModule,
        MatSelectModule,
        TreeTableModule,
        InputTextModule,
        TimelineModule,
        TagModule,
        QRCodeModule,
        ToastModule,
        ChartModule,
        ProgressSpinnerModule,
        SkeletonModule,
        CalendarModule,
        InputSwitchModule,
        ConfirmDialogModule,
        DialogModule,
        RadioButtonModule,
        InputTextareaModule,
        DropdownModule,
        SplitButtonModule,
        OverlayPanelModule
    ],
    entryComponents: [
        ConfirmationDialogComponent,
        UpdateReleaseModalComponent
    ],
    declarations: [AppComponent,
        PaginationComponent,
        DisclaimerComponent,
        CookiesComponent,
        GdprComponent,
        TestcomponentComponent,
        PatchnotesComponent,
        UserActivationComponent,
        MaintenanceComponent,
        FeedbackPopupComponent,
        IconSnackBarComponent,
        DriverChartComponent,
        UpdaterCheckerComponent,
        BillableTerminalsComponent,
        NotificationManagementComponent,
        MobileAppsComponent,
        T2UpdateBillingComponent,
        T2ProUpdateBillingComponent,
        SubscriptionComponent,
        IsProUserComponent,
        ManageSubCompanyComponent,
        // ReportSubscriptionBannerComponent,
        ManageSubCompanyBillingComponent,
        ReplaceValuesPipe,
        ReplaceAllValuesPipe
    ],
    providers: [
        RoutePartsService,
        FeedbackService,
        AuthGuard,
        AuthService,
        CookieService,
        AppSettings,
        AlertBar,
        DecimalPipe,
        NamePipe,
        MessageService,
        // ReportSubscriptionBannerComponent,
        ConfirmationService,
        {provide: ErrorHandler, useClass: AuthErrorHandler},
        {provide: HTTP_INTERCEPTORS, useClass: MyXsrfInterceptor, multi: true},
        {provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG}
    ],
    exports: [
        DriverChartComponent,
        IsProUserComponent,
        // ReportSubscriptionBannerComponent
    ],
    bootstrap: [AppComponent],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule {
}
