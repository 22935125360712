import { Component, OnInit } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import moment from './moment.override';
import { ApiinfoService } from './services/apiinfo.service';
import { DateAdapter } from '@angular/material/core';
import { PROFILE } from './helpers/globals';
import { CharttranslationService } from './services/rszoft/charttranslation.service';
import { AuthService } from './services/auth/auth.service';

declare var window: Window;

declare var gtag: Function;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    // name = window.location.host;

    static checkCurrentLangName() {
        if (localStorage.getItem('selectedLangCode') === 'HU') {
            localStorage.setItem('selectedLangName', '' + 'Hungarian');
        }
        if (localStorage.getItem('selectedLangCode') === 'EN') {
            localStorage.setItem('selectedLangName', '' + 'English');
        }
        if (localStorage.getItem('selectedLangCode') === 'RO') {
            localStorage.setItem('selectedLangName', '' + 'Romanian');
        }
        if (localStorage.getItem('selectedLangCode') === 'DE') {
            localStorage.setItem('selectedLangName', '' + 'German');
        }
        if (localStorage.getItem('selectedLangCode') === 'NL') {
            localStorage.setItem('selectedLangName', '' + 'Dutch');
        }
        if (localStorage.getItem('selectedLangCode') === 'FR') {
            localStorage.setItem('selectedLangName', '' + 'French');
        }
        if (localStorage.getItem('selectedLangCode') === 'DA') {
            localStorage.setItem('selectedLangName', '' + 'Danish');
        }
        if (localStorage.getItem('selectedLangCode') === 'ES') {
            localStorage.setItem('selectedLangName', '' + 'Spanish');
        }
        if (localStorage.getItem('selectedLangCode') === 'SL') {
            localStorage.setItem('selectedLangName', '' + 'Slovenian');
        }
        if (localStorage.getItem('selectedLangCode') === 'IT') {
            localStorage.setItem('selectedLangName', '' + 'Italian');
        }
        if (localStorage.getItem('selectedLangCode') === 'SK') {
            localStorage.setItem('selectedLangName', '' + 'Slovak');
        }
        if (localStorage.getItem('selectedLangCode') === 'BG') {
            localStorage.setItem('selectedLangName', '' + 'Bulgarian');
        }
        if (localStorage.getItem('selectedLangCode') === 'PT') {
            localStorage.setItem('selectedLangName', '' + 'Portuguese');
        }
        if (localStorage.getItem('selectedLangCode') === 'CS') {
            localStorage.setItem('selectedLangName', '' + 'Czech');
        }
        if (localStorage.getItem('selectedLangCode') === 'HR') {
            localStorage.setItem('selectedLangName', '' + 'Croatian');
        }
        if (localStorage.getItem('selectedLangCode') === 'UK') {
            localStorage.setItem('selectedLangName', '' + 'Ukrainian');
        }
        if (localStorage.getItem('selectedLangCode') === 'GR') {
            localStorage.setItem('selectedLangName', '' + 'Greek');
        }
    }

    static checkIfLoggedIn() {
        if ((!localStorage.getItem('loggedInUser')
            || localStorage.getItem('loggedInUser') === null)
            && location.hash !== '#/sessions/signin'
            && location.hash !== '#/maintenance'
            && location.hash !== '#/app'
            && (localStorage.getItem('savedPage') === null
                || localStorage.getItem('savedPage') === ''
                || localStorage.getItem('savedPage') === '')
            && location.hash !== '#/sessions/signup'
            && location.hash !== '#/activate/:response'
            && location.hash !== '#/sessions/forgot-password') {
            localStorage.setItem('savedPage', location.hash);
        }
    }

    static drawConsole() {
        console.log(
            '                                 _____________________________________________________\n' +
            '                                |                                                     |\n' +
            '                       _______  |                                                     |\n' +
            '                      / _____ | |                                                     |\n' +
            '                     / /(__) || |                                                     |\n' +
            '            ________/ / |OO| || |                                                     |\n' +
            '           |         |-------|| |                                                     |\n' +
            '          (|         |     -.|| |_______________________                              |\n' +
            '           |  ____   \\       ||_________||____________  |             ____      ____  |\n' +
            '          /| / __ \\   |______||     / __ \\   / __ \\   | |            / __ \\    / __ \\ |\\\n' +
            '          \\|| /  \\ |_______________| /  \\ |_| /  \\ |__| |___________| /  \\ |__| /  \\|_|/\n' +
            '             | () |                 | () |   | () |                  | () |    | () |\n' +
            '              \\__/                   \\__/     \\__/                    \\__/      \\__/\n' +
            '______________________________________________________________________________________________________\n');

        console.log(
            ' _______         _____  _    _   ____    _____           ______  ______ __          __ ______  ____  \n' +
            '|__   __| /\\    / ____|| |  | | / __ \\  / ____|   /\\    |  ____||  ____|\\ \\        / /|  ____||  _ \\ \n' +
            '   | |   /  \\  | |     | |__| || |  | || (___    /  \\   | |__   | |__    \\ \\  /\\  / / | |__   | |_) |\n' +
            '   | |  / /\\ \\ | |     |  __  || |  | | \\___ \\  / /\\ \\  |  __|  |  __|    \\ \\/  \\/ /  |  __|  |  _ < \n' +
            '   | | / ____ \\| |____ | |  | || |__| | ____) |/ ____ \\ | |     | |____    \\  /\\  /   | |____ | |_) |\n' +
            '   |_|/_/    \\_\\\\_____||_|  |_| \\____/ |_____//_/    \\_\\|_|     |______|    \\/  \\/    |______||____/ \n' +
            '______________________________________________________________________________________________________\n');

    }

    constructor(private readonly authService: AuthService,
        public title: Title,
        private matIconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        public router: Router,
        private translate: TranslateService,
        private apiInfo: ApiinfoService,
        private _adapter: DateAdapter<any>,
        private charttranslationServcie: CharttranslationService) {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                gtag('set', 'page', event.urlAfterRedirects);
                gtag('send', 'pageview');
            }
        });
        // this.authService.asr();
        this.checkLang();
        this.getIcons();
        AppComponent.drawConsole();
        AppComponent.checkIfLoggedIn();
    }

    ngOnInit(): void {

        this.translate.onLangChange.subscribe((event) => {
            // change html lang attribute
            document.documentElement.lang = event.lang
        });

        this.getApiInfo();
        this.changeTitle();
        this.translate.defaultLang = 'EN';
        this.registerGoogleAnalyticsRoutes();

        const hostname = window.location.hostname;
        if (hostname.includes('localhost')) {
            document.title =  `🟢 TS Local 🟢`;
        }
        if (hostname.includes('staging')) {
            document.title =  `🟡 TS Staging 🟡`;
        }
        if (hostname.includes('sandbox')) {
            document.title =  `🔴 TS Sandbox 🔴`;
        }

        

    }

    registerGoogleAnalyticsRoutes() {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                gtag('event', 'page_view', {
                    page_path: document.location.origin + event.urlAfterRedirects,
                    page_title: document.title
                })
            }
        });
    }

    changeTitle() {
        switch (PROFILE) {
            case 0: {
                document.title = 'TachoAnalytics';
                break;
            }
            case 1: {
                document.title = 'TachoDownload';
                const favicon = document.getElementById('favicon');
                favicon.setAttribute('type', 'image/png');
                favicon.setAttribute('href', '../assets/faviconTCD.png');
                break;
            }
            case 2: {
                document.title = 'HanseDis';
                const favicon = document.getElementById('favicon');
                favicon.setAttribute('type', 'image/png');
                favicon.setAttribute('href', '../assets/hanseDis-logo.png');
                break;
            }
        }
    }

    getApiInfo() {
        this.apiInfo.getApiInfo().subscribe((info) => {
        }, err => {
            if (err.status === 0) {
                // this.router.navigateByUrl('/maintenance');
                // this.router.navigate(['maintenance']);
                switch (PROFILE) {
                    case 0: {
                        // this.router.navigate(['maintenance']);
                        // window.open('https://tacho4safe.statuspage.io/', '_self');
                        break;
                    }
                    case 1: {
                        this.router.navigate(['maintenance']);
                        break;
                    }
                    case 2: {
                        this.router.navigate(['maintenance']);
                        break;
                    }
                }
            }
        });
    }

    getIcons() {
        this.matIconRegistry.addSvgIcon(
            'infosdevice_icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/infosicon.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'tacho4safe',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/tacho4safe.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'kormany',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/kormany.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'kormany_1',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/kormany_1.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'kormany_2',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/kormany_2.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'kormany_3',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/kormany_3.svg')
        );

        this.matIconRegistry.addSvgIcon(
            '24h',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/24h.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'break',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/break.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'daily_work',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/w24.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'weekly_work',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/wv.svg')
        );


        this.matIconRegistry.addSvgIcon(
            'work',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/work.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'bed',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/bed.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'usericon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/usericon.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'usericon_colored',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/usericon_colored.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'truck_activ',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/truck_activ.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'truck',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/truck.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'user_activ',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/user_activ.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'daily_rest',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/daily_rest.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'next_work',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/next_work.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'weekly_rest',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/weekly_rest.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'available',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/available.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'available_colored',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/available_colored.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'break_colored',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/break_colored.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'kormany_colored',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/kormany_colored.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'work_colored',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/work_colored.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'bed_colored',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/bed_colored.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'hossza',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/hossza.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'kezdet',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/kezdet.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'note',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/note.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'status',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/status.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'go',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/go.svg')
        );

        this.matIconRegistry.addSvgIcon(
            'stop',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/stop.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'insignia',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/insignia.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'EN',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/US.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'SL',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/SL.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'SK',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/SK.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'RO',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/RO.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'NL',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/NL.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'IT',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/IT.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'UK',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/UK.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'GR',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/GR.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'BG',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/BG.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'HR',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/HR.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'HU',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/HU.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'FR',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/FR.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'ES',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/ES.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'DA',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/DA.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'DE',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/DE.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'EU',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/EU.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'PT',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/PT.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'PL',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/PL.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'CS',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/CS.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'raw',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/raw.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'custom',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/custom.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'SV',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/SV.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'sftp',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/sftp.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'webhook',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/webhook.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'amazon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/amazon.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'azure',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/azure.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'notDownload',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/notDownload.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'redDownload',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/redDownload.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'ftp',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/ftp.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'play_icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/play.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'work_new_colored_icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/work_new_colored.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'kormany_new_colored_icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/kormany_new_colored.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'available_new_colored_icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/available_new_colored.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'bed_new_colored_icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/bed_new_colored.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'driver_card_icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/driver_card_icon.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'timezone_icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/timezone_icon.svg')
        );
        this.matIconRegistry.addSvgIcon(
            't2Device_icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/t2Device_icon.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'billableTerminal',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/billable-terminal.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'track_icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/track_icon.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'track_outline_icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/track_outline_icon.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'completed_icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/completed_icon.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'chart_name_icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/chart_name_icon.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'driver_icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/driver_icon.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'ferry_icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/ferry_icon.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'no_data_icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/no_data_icon.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'red_clock_icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/red_clock_icon.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'download_icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/download_icon.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'download_icon2',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/download_icon2.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'file_download_off_black_icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/file_download_off_black_icon.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'file_download_off_grey_icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/file_download_off_grey_icon.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'change_vehicle_icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/change_vehicle_icon.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'print_icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/print_icon.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'excel',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/excel.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'csv_icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/csv_icon.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'driver_icon_blue',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/driver_icon_blue.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'driver_icon_grey',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/driver_icon_grey.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'driver_icon_white',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/driver_icon_white.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'dashboard_chart_icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/dashboard_chart_icon.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'dashboard_datamatch_icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/dashboard_datamatch_icon.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'dashboard_weekly_overview_icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/dashboard_weekly_overview_icon.svg')
        )
        this.matIconRegistry.addSvgIcon(
            'dashboard_checklist_report_icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/dashboard_checklist_report_icon.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'dashboard_expenses_icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/dashboard_expenses_icon.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'dashboard_detailedview_icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/dashboard_detailedview_icon.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'dashboard_offenses_icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/dashboard_offenses_icon.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'dashboard_driver_attestation_icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/dashboard_driver_attestation_icon.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'route_report_icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/route_report_icon.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'track_icon_grey',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/track_icon_grey.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'track_icon_blue',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/track_icon_blue.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'dashboard_infregments_icon_red',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/dashboard_infregments_icon_red.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'location_icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/location_icon.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'no_data_icon2',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/no_data_icon2.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'calendar_icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/calendar_icon.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'blue_down_arrow',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/blue_down_arrow.png')
        );
        this.matIconRegistry.addSvgIcon(
            'summa_icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/summa_icon.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'data_available_blue_icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/data_available_blue_icon.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'data_available_white_icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/data_available_white_icon.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'data_available_icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/data_available_icon.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'data_available_outline_icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/data_available_outline_icon.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'no_data_grey_icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/no_data_grey_icon.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'no_data_white_icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/no_data_white_icon.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'track_icon_white',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/track_icon_white.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'speed_icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/speed_icon.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'no_card_icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/no_card_icon.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'control_icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/control_icon.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'event_icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/event_icon.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'fault_icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/fault_icon.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'down_arrow_icon',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/down_arrow_icon.svg')
        );
    }

    checkLang() {
        // console.log('selectedLangCode', navigator.language);

        if (!(localStorage.getItem('selectedLangCode'))) {
            if (navigator.language === 'en' || navigator.language === 'en-US') {
                localStorage.setItem('selectedLangCode', '' + 'EN');
                localStorage.setItem('selectedLangName', '' + 'English');
                this.translate.use('EN');
                moment.locale('EN');
                this._adapter.setLocale('EN');
            }

            if (navigator.language === 'hu' || navigator.language === 'hu-HU') {
                localStorage.setItem('selectedLangCode', '' + 'HU');
                this.translate.use('HU');
                localStorage.setItem('selectedLangName', '' + 'Hungarian');
                moment.locale('HU');
                this._adapter.setLocale('HU');
            }

            if (navigator.language === 'ro' || navigator.language === 'ro-RO') {
                localStorage.setItem('selectedLangCode', '' + 'RO');
                this.translate.use('RO');
                localStorage.setItem('selectedLangName', '' + 'Romanian');
                moment.locale('RO');
                this._adapter.setLocale('RO');
            }

            if (navigator.language === 'de' || navigator.language === 'de-DE') {
                localStorage.setItem('selectedLangCode', '' + 'DE');
                this.translate.use('DE');
                localStorage.setItem('selectedLangName', '' + 'German');
                moment.locale('DE');
                this._adapter.setLocale('DE');
            }

            if (navigator.language === 'nl' || navigator.language === 'nl-NL') {
                localStorage.setItem('selectedLangCode', '' + 'NL');
                this.translate.use('NL');
                localStorage.setItem('selectedLangName', '' + 'Dutch');
                moment.locale('NL');
                this._adapter.setLocale('NL');
            }

            if (navigator.language === 'fr' || navigator.language === 'fr-FR') {
                localStorage.setItem('selectedLangCode', '' + 'FR');
                this.translate.use('FR');
                localStorage.setItem('selectedLangName', '' + 'French');
                moment.locale('FR');
                this._adapter.setLocale('FR');
            }

            if (navigator.language === 'da' || navigator.language === 'da-DA') {
                localStorage.setItem('selectedLangCode', '' + 'DA');
                this.translate.use('DA');
                localStorage.setItem('selectedLangName', '' + 'Danish');
                moment.locale('DA');
                this._adapter.setLocale('DA');
            }
            if (navigator.language === 'es' || navigator.language === 'es-ES') {
                localStorage.setItem('selectedLangCode', '' + 'ES');
                this.translate.use('ES');
                localStorage.setItem('selectedLangName', '' + 'Spanish');
                moment.locale('ES');
                this._adapter.setLocale('ES');
            }
            if (navigator.language === 'it' || navigator.language === 'it-IT') {
                localStorage.setItem('selectedLangCode', '' + 'IT');
                this.translate.use('IT');
                localStorage.setItem('selectedLangName', '' + 'Italian');
                moment.locale('IT');
                this._adapter.setLocale('IT');
            }
            if (navigator.language === 'sl' || navigator.language === 'sl-SL') {
                localStorage.setItem('selectedLangCode', '' + 'SL');
                this.translate.use('SL');
                localStorage.setItem('selectedLangName', '' + 'Slovenian');
                moment.locale('SL');
                this._adapter.setLocale('SL');
            }
            if (navigator.language === 'sk' || navigator.language === 'sk-SK') {
                localStorage.setItem('selectedLangCode', '' + 'SK');
                this.translate.use('SK');
                localStorage.setItem('selectedLangName', '' + 'Slovak');
                moment.locale('SK');
                this._adapter.setLocale('SK');
            }
            if (navigator.language === 'bg' || navigator.language === 'bg-BG') {
                localStorage.setItem('selectedLangCode', '' + 'BG');
                this.translate.use('BG');
                localStorage.setItem('selectedLangName', '' + 'Bulgarian');
                moment.locale('BG');
                this._adapter.setLocale('BG');
            }
            if (navigator.language === 'cs' || navigator.language === 'cs-CZ') {
                localStorage.setItem('selectedLangCode', '' + 'CS');
                this.translate.use('CS');
                localStorage.setItem('selectedLangName', '' + 'Czech');
                moment.locale('CS');
                this._adapter.setLocale('CS');
            }
            if (navigator.language === 'sv' || navigator.language === 'sv-SE') {
                localStorage.setItem('selectedLangCode', '' + 'SV');
                this.translate.use('SV');
                localStorage.setItem('selectedLangName', '' + 'Svenska');
                moment.locale('SV');
                this._adapter.setLocale('SV');
            }
            if (navigator.language === 'hr' || navigator.language === 'hr') {
                localStorage.setItem('selectedLangCode', '' + 'HR');
                this.translate.use('HR');
                localStorage.setItem('selectedLangName', '' + 'Croatian');
                moment.locale('HR');
                this._adapter.setLocale('HR');
            }
            if (navigator.language === 'uk' || navigator.language === 'uk') {
                localStorage.setItem('selectedLangCode', '' + 'UK');
                this.translate.use('UK');
                localStorage.setItem('selectedLangName', '' + 'Ukrainian');
                moment.locale('UK');
                this._adapter.setLocale('UK');
            }
            if (navigator.language === 'gr' || navigator.language === 'gr') {
                localStorage.setItem('selectedLangCode', '' + 'GR');
                this.translate.use('GR');
                localStorage.setItem('selectedLangName', '' + 'Greek');
                moment.locale('GR');
                this._adapter.setLocale('GR');
            }
            if (navigator.language === 'pl' || navigator.language === 'pl') {
                localStorage.setItem('selectedLangCode', '' + 'PL');
                this.translate.use('PL');
                localStorage.setItem('selectedLangName', '' + 'Polish');
                moment.locale('PL');
                this._adapter.setLocale('PL');
            }
            if (navigator.language === 'pt' || navigator.language === 'pt') {
                localStorage.setItem('selectedLangCode', '' + 'PT');
                this.translate.use('PT');
                localStorage.setItem('selectedLangName', '' + 'Portuguese');
                moment.locale('PT');
                this._adapter.setLocale('PT');
            }
        } else {
            this.translate.use(localStorage.getItem('selectedLangCode'));
            moment.locale(localStorage.getItem('selectedLangCode'));
            AppComponent.checkCurrentLangName();
            this._adapter.setLocale(localStorage.getItem('selectedLangCode'));
        }
    }
}
