<div class="flex flex-col md:flex-row w-screen h-screen">
    <div class="blurFilter h-full w-2/3 hidden md:flex flex-row justify-end "
        style=" background-image: url('assets/images/TachoAnalytics.jpg'); background-size: cover;">
    </div>
    <div class="flex flex-col bg-white h-full w-full md:w-1/3">
        <div class="languageBar w-full flex flex-row justify-end">
            <div class="flex gap-1 flex-row py-2" *ngFor="let lang of availableLangs(); index as i;">
                <div (click)="changeLang(lang)" [ngClass]="{'text-slate-800 border-b-2 border-tachosafe-500': lang === selectedLang(), 'text-slate-200': lang !== selectedLang(),
                        'border-r-2 border-r-slate-200': i !== availableLangs().length - 1}"
                    class="langItem flex items-center justify-center w-8 cursor-pointer">
                    <span class="font-bold">{{ lang }}</span>
                </div>
            </div>
        </div>
        <div class="w-full h-full gap-8 flex flex-col justify-center items-center">
            <div class="header h-full flex flex-col items-center justify-center">
                <h1 class="text-4xl font-bold text-[#253ca4]">TachoAnalytics</h1>
            </div>
            <div>
                <img *ngIf="profile === 3" src="assets/images/logo-track-gps.png" alt="" width="150"
                    style="margin-bottom: 1rem;" class="respImg">
            </div>
            <form [formGroup]="signinForm" (ngSubmit)="signin()"
                class="w-full h-full flex flex-col items-center justify-center">
                <h2 class="font-semibold mb-8">{{'Sign in' | translate}}</h2>

                <div class="override gap-2 px-6 w-full flex flex-col items-center justify-center">
                    <input class="w-full rounded-md border-2 border-slate-300 p-2 mb-4" name="Username"
                        placeholder="{{'Username' | translate}}" type="text" id="username"
                        [formControl]="signinForm.controls['username']">

                    <div class="w-full relative">
                        <input class="w-full rounded-md border-2 border-slate-300 p-2 mb-4" name="Password"
                            placeholder="{{'Password' | translate}}" type="{{passwordIsHidden?'password':'text'}}"
                            id="password" [formControl]="signinForm.controls['password']">
                        <mat-icon class="cursor-pointer absolute top-2 right-4"
                            (click)="passwordIsHidden=!passwordIsHidden">{{passwordIsHidden?'visibility':'visibility_off'}}</mat-icon>
                    </div>
                </div>
                <div class="w-full px-6 flex flex-row justify-between items-center">
                    <div class="flex flex-row gap-2 items-center">
                        <input type="checkbox" id="rememberMe" name="rememberMe" value="rememberMe"
                            [formControl]="signinForm.controls['rememberMe']">
                        <label class="text-sm font-bold" for="rememberMe">{{'Remember me' | translate}}</label>
                    </div>
                    <div>
                        <a class="text-blue-500 text-sm font-bold"
                            [routerLink]="['/sessions/forgot-password']">{{'Forgot password?' | translate}}</a>
                    </div>
                </div>

                <div class="w-full flex flex-col items-center justify-center mt-6 gap-4">
                    <button [ngClass]="{'bg-tachosafe-500':!loginButtonDisabled, 'bg-slate-300': loginButtonDisabled}"
                        class="bg-tachosafe-500 px-10 text-white p-2 rounded-md mt-4"
                        [disabled]="!signinForm.valid || loginButtonDisabled" type="submit">{{'Sign in' |
                        translate}}</button>
                    <span class="px-10 text-red-500">{{error}}</span>
                </div>

            </form>
            <div class="w-full h-full flex flex-col justify-end items-center">
                <div class="mb-10">
                    <!-- © AROBS Transilvania Software -->
                </div>
            </div>
        </div>
    </div>

</div>