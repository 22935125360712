export const environment = {
    production: true,
    appVersion: require('../../package.json').version,
    //API_URL: 'https://tachosafe.ro:9088/TachoSafe/',
    //CHART_API_URL: 'https://tachosafe.ro:8099/charts-generator-api/v0/',
    //DEVICE_QR_URL: 'https://staging.tacho4safe.com/#/app',
    PROFILE: 3,
    PRINT_TEST: false,
    I18N_URL: 'https://i18n.tacho5safe.com',
    I18N_KEY: 'tgpak_gvptiytqoe4ha4deonwdmzlonbrg23lhorsw23lbnaytg'
};


// PROFILES
//      1. Tacho4Safe - 0
//      2. TCD - 1
//      3. HanseDis - 2
//      4. TrackGPS - 3
